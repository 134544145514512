import { type Country } from '@/types';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addCountry, countryEdit, country } from './actions';
import { isProdEnvironment } from '@/utils/helperFunctions';

export interface CountryState {
  isLoading: boolean;
  country: Country;
}

const initialState: CountryState = {
  isLoading: false,
  country: {
    countryCode: '',
    countryName: '',
    internationalCallingCode: 0,
    isEnabled: false,
    isTesting: !isProdEnvironment(),
    minPhoneLength: 0,
    maxPhoneLength: 0,
    currencies: [],
  },
};

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    resetCountry() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(addCountry.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(addCountry.fulfilled, (state, action: PayloadAction<Country>) => {
      state.isLoading = false;
      state.country = action.payload;
    });
    builder.addCase(addCountry.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(country.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(country.fulfilled, (state, action: PayloadAction<Country>) => {
      state.isLoading = false;
      state.country = action.payload;
    });
    builder.addCase(country.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(countryEdit.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(countryEdit.fulfilled, (state, action: PayloadAction<Country>) => {
      state.isLoading = false;
      state.country = action.payload;
    });
    builder.addCase(countryEdit.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { resetCountry } = countrySlice.actions;
