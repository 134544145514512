import { useMemo, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from '@/lib/redux';
import { useTranslation } from 'react-i18next';
import useBackButtonCallback from '@/utils/hooks/useBackButtonCallback';
import NavigationTabs from '@/components/common/navigation-tabs/NavigationTabs';
import BackButton from '@/components/common/button/implementations/BackButton';
import { type TabInfo } from '@/utils/interfaces';
import styles from './CountryConfigurationsLayout.module.scss';
import Typography from '@/components/common/typography/Typography';
import { resetCountry } from '@/lib/redux/slices/country/slice';
import { country } from '@/lib/redux/slices/country/actions';

export default function CountryConfigurationsLayout(): React.JSX.Element {
  const { t } = useTranslation('configurations');
  const goBackCb = useBackButtonCallback();
  const dispatch = useAppDispatch();

  const { countryCode } = useParams<{ countryCode: string }>();

  const tabs: TabInfo[] = useMemo(() => {
    return [
      { linkText: 'Country information', linkPath: 'country-information' },
      { linkText: 'Limits', linkPath: 'limits' },
    ];
  }, []);

  useEffect(() => {
    if (countryCode !== undefined && countryCode !== 'new') {
      void dispatch(country({ countryCode }));
    }
    return () => {
      dispatch(resetCountry());
    };
  }, [countryCode, dispatch]);

  return (
    <>
      <div className={styles.backButton}>
        <BackButton onClick={goBackCb}>{t('new-country.button-back')}</BackButton>
      </div>
      <div className={styles.card}>
        <div className={styles.header}>
          <Typography variant='h1'>{t('new-country.new-country')}</Typography>
          <NavigationTabs tabs={tabs} />
        </div>
        <Outlet />
      </div>
    </>
  );
}
