import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import Typography from '@/components/common/typography/Typography';
import Button from '@/components/common/button/Button';
import styles from './CountriesConfigurations.module.scss';
import { Permissions } from '@/types';
import ContentBox from '@/components/common/content-box/ContentBox';

export default function CountriesConfigurations(): React.JSX.Element {
  const { t } = useTranslation('configurations');

  const navigate = useNavigate();

  useDocumentTitle(t('countries-page-title'));

  const handleNewCountry = (): void => {
    navigate('/configurations/countries/new');
  };

  return (
    <ContentBox>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>Country configuration</Typography>
        <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
          <Button
            textTransform='uppercase'
            onClick={handleNewCountry}
          >
            + {t('button-new-country')}
          </Button>
        </UserPermissionsGuard>
      </div>
    </ContentBox>
  );
}
