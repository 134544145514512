import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import Home from '@/pages/home/Home';
import OverviewTab from '@/pages/customer/overview/OverviewTab';
import TransactionHistoryTab from '@/pages/customer/transaction-history/TransactionHistoryTab';
import KycRiskTab from '@/pages/customer/kyc-risk/KycRiskTab';
import LimitsTab from '@/pages/customer/limits/LimitsTab';
import CustomerLayout from '@/pages/customer/CustomerLayout';
import CustomerDocumentsLayout from '@/pages/customer/documents/CustomerDocumentsLayout';
import CustomerInfoLayout from '@/pages/customer/CustomerInfoLayout';
import CustomerInfoPage from '@/pages/customer/CustomerInfoPage';
import IdentityDocumentsPage from '@/pages/customer/documents/identity-docs/IdentityDocumentsPage';
import IdentityDocumentsLayout from '@/pages/customer/documents/identity-docs/IdentityDocumentsLayout';
import SelfiePage from '@/pages/customer/documents/identity-docs/selfie/SelfiePage';
import IdDocsReviewPage from '@/pages/customer/documents/identity-docs/review/IdDocsReviewPage';
import AuditLogsPage from '@/pages/customer/audit-logs-page/AuditLogsPage';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { FeatureFlagKeys, Permissions } from '@/types';
import Error404 from '@/pages/error/error-404/Error404';
import TransactionHistoryDetailWidget from '@/components/customer/transaction-history/TransactionHistoryDetailWidget';
import TransactionHistoryWidget from '@/components/customer/transaction-history/TransactionHistoryWidget';
import BusinessAccountsPage from '@/pages/bussiness-accounts/BusinessAccountsPage';
import DocumentQueueLayout from '@/pages/document-review-queue/DocumentQueueLayout';
import IdentityDocs from '@/pages/document-review-queue/identity-docs/IdentityDocs';
import AddressDocs from '@/pages/document-review-queue/address-docs/AddressDocs';
import AddressDocumentsPage from '@/pages/customer/documents/address-docs/AddressDocumentsPage';
import Overview from '@/pages/bussiness-accounts/overview/Overview';
import TransactionHistory from '@/pages/bussiness-accounts/transaction-history/TransactionHistory';
import AccessManagementPage from '@/pages/access-management/AccessManagementPage';
import UsersPage from '@/pages/access-management/users/UsersPage';
import RolesAndPermissions from '@/pages/access-management/roles-and-permissions/RolesAndPermissions';
import FeatureFlagGuard from '@/components/feature-flag/FeatureFlagGuard';
import CardsTab from '@/pages/customer/cards/CardsTab';
import CardDetailsWidget from '@/components/customer/cards/CardDetailsWidget';
import CardsWidget from '@/components/customer/cards/CardsWidget';
import CountriesConfigurations from '@/pages/configurations/countries-configurations/CountriesConfigurations';
import ConfigurationsLayout from '@/pages/configurations/ConfigurationsLayout';
import CountryConfigurationsLayout from '@/pages/configurations/countries-configurations/countries-configurations-layout/CountryConfigurationsLayout';
import CountryInformation from '@/pages/configurations/countries-configurations/countries-configurations-layout/country-information/CountryInformation';
import FeatureFlags from '@/pages/configurations/feature-flags/FeatureFlags';

export const routes = [
  {
    path: '/',
    element: (
      <Navigate
        to='/search'
        replace
      />
    ),
  },
  {
    path: '/search',
    element: <Home />,
  },
  {
    path: '/customer/:customerId',
    element: <CustomerLayout />,
    children: [
      {
        path: 'documents',
        element: <CustomerDocumentsLayout />,
        children: [
          {
            path: 'identity-docs/:documentsId',
            element: (
              <UserPermissionsGuard
                permission={Permissions.kycriskDocsView}
                redirect
              >
                <IdentityDocumentsLayout />
              </UserPermissionsGuard>
            ),
            children: [
              { path: 'selfie', element: <SelfiePage /> },
              { path: '', element: <IdentityDocumentsPage /> },
            ],
          },
          {
            path: 'review/identity-docs/:documentsId',
            element: (
              <UserPermissionsGuard
                permission={Permissions.docQueueRead}
                redirect
              >
                <IdentityDocumentsLayout review />
              </UserPermissionsGuard>
            ),
            children: [{ path: '', element: <IdDocsReviewPage /> }],
          },
          {
            path: 'address-docs/:documentsId',
            element: (
              <UserPermissionsGuard
                permission={Permissions.kycriskDocsView}
                redirect
              >
                <AddressDocumentsPage />
              </UserPermissionsGuard>
            ),
          },
          {
            path: 'review/address-docs/:documentsId',
            element: (
              <UserPermissionsGuard
                permission={Permissions.docQueueRead}
                redirect
              >
                <AddressDocumentsPage review />
              </UserPermissionsGuard>
            ),
          },
        ],
      },
      {
        path: 'audit-logs',
        element: (
          <UserPermissionsGuard
            permission={Permissions.kycriskOverview}
            redirect
          >
            <AuditLogsPage />
          </UserPermissionsGuard>
        ),
      },
      {
        element: <CustomerInfoLayout />,
        children: [
          {
            path: 'overview',
            element: (
              <UserPermissionsGuard
                permission={Permissions.customerOverview}
                redirect
              >
                <OverviewTab />
              </UserPermissionsGuard>
            ),
          },
          {
            path: 'cards',
            element: (
              <UserPermissionsGuard
                permission={Permissions.cardOverviewRead}
                redirect
              >
                <CardsTab />
              </UserPermissionsGuard>
            ),
            children: [
              { path: '', element: <CardsWidget /> },
              {
                path: ':cardId',
                element: <CardDetailsWidget />,
              },
            ],
          },
          {
            path: 'transaction-history',
            element: (
              <UserPermissionsGuard
                permission={Permissions.transactionsOverview}
                redirect
              >
                <TransactionHistoryTab />
              </UserPermissionsGuard>
            ),
            children: [
              {
                path: '',
                element: <TransactionHistoryWidget />,
              },
              {
                path: ':transactionId',
                element: <TransactionHistoryDetailWidget />,
              },
            ],
          },
          {
            path: 'kyc-risk',
            element: (
              <UserPermissionsGuard
                permission={Permissions.kycriskOverview}
                redirect
              >
                <KycRiskTab />
              </UserPermissionsGuard>
            ),
          },
          {
            path: 'limits',
            element: (
              <FeatureFlagGuard
                featureFlag={FeatureFlagKeys.FeatureDailyLimits}
                redirect
              >
                <LimitsTab />
              </FeatureFlagGuard>
            ),
          },
          {
            path: '',
            element: <CustomerInfoPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/document-review-queue',
    element: (
      <UserPermissionsGuard
        permission={Permissions.docQueueView}
        redirect
      >
        <DocumentQueueLayout />
      </UserPermissionsGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to='identity-docs'
            replace
          />
        ),
      },
      {
        path: 'identity-docs',
        element: <IdentityDocs />,
      },
      {
        path: 'address-docs',
        element: <AddressDocs />,
      },
    ],
  },
  {
    path: '/configurations',
    element: (
      <FeatureFlagGuard
        featureFlag={FeatureFlagKeys.Configurations}
        redirect
      >
        <ConfigurationsLayout />
      </FeatureFlagGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to='countries'
            replace
          />
        ),
      },
      {
        path: 'countries',
        element: (
          <UserPermissionsGuard
            permission={Permissions.countryConfigRead}
            redirect
          >
            <Outlet />
          </UserPermissionsGuard>
        ),
        children: [
          {
            path: '',
            element: <CountriesConfigurations />,
          },
          {
            path: ':countryCode',
            element: <CountryConfigurationsLayout />,
            children: [
              {
                path: '',
                element: (
                  <Navigate
                    to='country-information'
                    replace
                  />
                ),
              },
              {
                path: 'country-information',
                element: <CountryInformation />,
              },
              {
                path: 'limits',
                element: '',
              },
            ],
          },
        ],
      },
      {
        path: 'feature-flags',
        element: <FeatureFlags />,
      },
    ],
  },
  {
    path: '/business-accounts',
    element: (
      <UserPermissionsGuard
        permission={Permissions.businessAccountsRead}
        redirect
      >
        <BusinessAccountsPage />
      </UserPermissionsGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to='overview'
            replace
          />
        ),
      },
      {
        path: 'overview',
        element: <Overview />,
      },
      {
        path: 'transaction-history',
        element: <TransactionHistory />,
      },
    ],
  },
  {
    path: '/access-management',
    element: (
      <UserPermissionsGuard
        permission={Permissions.accessManagementRead}
        redirect
      >
        <AccessManagementPage />
      </UserPermissionsGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to='users'
            replace
          />
        ),
      },
      {
        path: 'users',
        element: <UsersPage />,
      },
      {
        path: 'roles-and-permissions',
        element: <RolesAndPermissions />,
      },
    ],
  },
  {
    path: '*',
    element: <Error404 />,
  },
];

export const router = createBrowserRouter(routes);
