import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AuditLogs.module.scss';
import Table from '@/components/common/tables/Table';
import Button from '@/components/common/button/Button';
import ActionModal from './ActionModal/ActionModal';
import TextArea from '@/components/common/inputs/TextArea';
import LinkButton from '@/components/common/button/LinkButton';
import Spinner from '@/components/common/spinners/Spinner';
import { useAppDispatch, useAppSelector, type RootState } from '@/lib/redux';
import { getAuditLogsHeaders } from '@/utils/headers';
import { useParams } from 'react-router';
import { addAuditLogsComment } from '@/lib/redux/slices/audit-logs-comment/actions';
import { reset } from '@/lib/redux/slices/audit-logs-comment/slice';
import { resetAuditLogs } from '@/lib/redux/slices/audit-logs/slice';
import { useAuditLogs } from '@/utils/hooks/useAuditLogs';
import { toUtcDateString } from '@/utils/dateTimeUtils';

const AuditLogs: React.FC = () => {
  const ADD_COMMENT_MAX_LENGTH = 200;

  const { customerId } = useParams();
  const ITEMS_PER_PAGE = 5;
  const dispatch = useAppDispatch();
  const { isLoading, auditLogs, refetch } = useAuditLogs(customerId, ITEMS_PER_PAGE);
  const { loading, success } = useAppSelector((state: RootState) => state.auditLogsComment);
  const { t } = useTranslation('customerOverview');
  const auditLogsHeaders = useMemo(() => getAuditLogsHeaders(t), [t]);

  const [openAddCommentModal, setOpenAddCommentModal] = useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (success) {
      dispatch(reset());
      dispatch(resetAuditLogs());
      handleCloseAddComment();
      refetch();
    }
  }, [success, refetch, dispatch]);

  const handleOnAddComment = (): void => setOpenAddCommentModal(true);

  const handleCloseAddComment = (): void => {
    setOpenAddCommentModal(false);
    setComment('');
  };

  const handleConfirm = (): void => {
    if (customerId) {
      void dispatch(addAuditLogsComment({ customerId, comment }));
    }
  };

  const handleInputComment = (value: string): void => {
    setComment(() => value);
  };

  return (
    <div className={styles.main}>
      <div className={styles.headerBox}>
        <div className={styles.title}>{t('widgets.audit-logs.header-title')}</div>
        <div className={styles.actions}>
          <Button
            onClick={handleOnAddComment}
            textTransform='uppercase'
          >
            {t('widgets.audit-logs.add-comment')}
          </Button>
          <LinkButton
            href={`/customer/${customerId}/audit-logs`}
            target='_blank'
          >
            {t('widgets.audit-logs.see-all')}
          </LinkButton>
        </div>
      </div>
      {isLoading && <Spinner />}
      <Table cols={10}>
        {!isLoading && auditLogsHeaders.map(header => <Table.Head key={header.label}>{header.label}</Table.Head>)}
        {!isLoading &&
          auditLogs.map(logs => (
            <Table.Row key={logs.id}>
              <Table.Cell>{toUtcDateString(logs.timestamp)}</Table.Cell>
              <Table.Cell>{logs.event}</Table.Cell>
              <Table.Cell>{logs.user}</Table.Cell>
              <Table.Cell>{logs?.role}</Table.Cell>
              <Table.Cell>{logs.source}</Table.Cell>
              <Table.Cell>{logs.result}</Table.Cell>
              <Table.Cell>{logs.description}</Table.Cell>
              <Table.Cell>{logs.category}</Table.Cell>
              <Table.Cell>{logs?.ip}</Table.Cell>
              <Table.Cell>{logs.deviceInfo}</Table.Cell>
            </Table.Row>
          ))}
      </Table>
      {openAddCommentModal && (
        <ActionModal
          title={t('widgets.audit-logs.comment', { currentLength: comment.length, maxLength: ADD_COMMENT_MAX_LENGTH })}
          disabledButton={loading || comment.trim().length === 0 || !customerId}
          confirmButtonLabel={t('widgets.audit-logs.save-button')}
          onClose={handleCloseAddComment}
          onConfirm={handleConfirm}
        >
          <TextArea
            value={comment}
            rows={3}
            maxLength={ADD_COMMENT_MAX_LENGTH}
            onChange={handleInputComment}
          />
        </ActionModal>
      )}
    </div>
  );
};

export default AuditLogs;
