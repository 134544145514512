import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import type { TabInfo } from '@/utils/interfaces';

import styles from './NavigationTab.module.scss';

function NavigationTab({ tab, selected }: Readonly<{ tab: TabInfo; selected: boolean }>): React.JSX.Element {
  const tabItemClasses = classNames(styles['navigation-tabs__item'], {
    [styles['navigation-tabs__item--active']]: selected,
  });

  return (
    <li className={tabItemClasses}>
      <Link
        className={styles['navigation-tabs__link']}
        to={tab.linkPath}
      >
        {tab.linkText}
      </Link>
    </li>
  );
}

export default NavigationTab;
