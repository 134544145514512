import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@/components/common/tables/Table';
import { getAuditLogsHeaders } from '@/utils/headers';
import { useParams } from 'react-router';
import { useAuditLogs } from '@/utils/hooks/useAuditLogs';
import { type RootState, useAppSelector } from '@/lib/redux';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import TablePagination from '@/components/common/table-pagination/TablePagination';
import Spinner from '@/components/common/spinners/Spinner';
import ContentBox from '@/components/common/content-box/ContentBox';

import styles from './AuditLogsPage.module.scss';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';

const AuditLogsPage: React.FC = () => {
  const { t } = useTranslation('customerOverview');
  const auditLogsHeaders = useMemo(() => getAuditLogsHeaders(t), [t]);
  const { customerId } = useParams();
  const [itemsPerPage] = useItemsPerPage();

  const { count, isLoading, page, auditLogs, setPage } = useAuditLogs(customerId, itemsPerPage);

  const customer = useAppSelector((state: RootState) => state.customer.customer);
  useDocumentTitle(
    customer
      ? t('widgets.audit-logs.page-title', { customerFullName: `${customer.firstName} ${customer.lastName}` })
      : '',
  );

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1>{t('widgets.audit-logs.header-title')}</h1>
      </div>
      <ContentBox>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className={styles['table-wrapper']}>
            <Table cols={10}>
              {auditLogsHeaders.map(header => (
                <Table.Head key={header.label}>{header.label}</Table.Head>
              ))}
              {auditLogs.map(log => (
                <Table.Row key={log.id}>
                  <Table.Cell>{toUtcDateString(log.timestamp)}</Table.Cell>
                  <Table.Cell>{log.event}</Table.Cell>
                  <Table.Cell>{log.user}</Table.Cell>
                  <Table.Cell>{log.role}</Table.Cell>
                  <Table.Cell>{log.source}</Table.Cell>
                  <Table.Cell>{log.result}</Table.Cell>
                  <Table.Cell>{log.description}</Table.Cell>
                  <Table.Cell>{log.category}</Table.Cell>
                  <Table.Cell>{log.ip}</Table.Cell>
                  <Table.Cell>{log.deviceInfo}</Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </div>
        )}
        <TablePagination
          count={count}
          page={page}
          setPage={setPage}
        />
      </ContentBox>
    </div>
  );
};

export default AuditLogsPage;
